import React from 'react';
import { useCallback } from 'react';
import { Route, Redirect } from 'react-router';

import { AuthRepository } from 'app/repositories/auth.repository';
import { useStoredUser, useStoredCarrierDetail } from 'app/hooks/store/use-stored-user.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';

interface OwnProps {
  path: string;
  render?: (innerProps) => JSX.Element;
  children: React.ReactNode;
}

type Props = OwnProps;

export const UnauthenticatedRoute: React.FC<Props> = ({render, children, ...props}) => {
  const user = useStoredUser();
  const activeMembershipPermissions = useSelector(s => s.userManagement?.activeMembership?.permissions);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const auth = useRepository(AuthRepository);

  const renderFn = useCallback(
    innerProps => isAuthenticated ? <Redirect to={auth.getBestPotentialUserStartPageRoute(user, activeMembershipPermissions)}/> : (render ? render(innerProps) : children),
    [auth, user, render, children]
  );

  return  <Route {...props} render={renderFn}/>;
};
