import { Switch, Route } from "react-router";
import { AppRoute } from "@app/routesEnum";
import { SiteSection } from "@shared/components/analytics/site-section.component";
import { UnauthenticatedRoute } from "@shared/components/route/unauthenticated-route.component";
import { LazyRegistrationPageV2 } from 'features/registration/index';

export default function RegistrationRoutes() {

    return (
        <SiteSection name="Registration">
            <UnauthenticatedRoute path={AppRoute.REGISTRATION}>
                <Switch>
                    <Route path={AppRoute.REGISTRATION} exact component={LazyRegistrationPageV2} />
                </Switch>
            </UnauthenticatedRoute>
        </SiteSection>
    )
}
